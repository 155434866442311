@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-icon {
  display: block;

  &--sm {
    width: 1rem;
    path {
      width: 1rem;
    }
  }

  &--md {
    width: 1.25rem;
    path {
      width: 1.25rem;
    }
  }

  &--lg {
    path {
      width: 1.5rem;
    }
    width: 1.5rem;
  }

  &--full {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
