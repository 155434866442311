@import '../../styles/globals/mixins';
@import '../../styles/globals/variables';

.ac-layout-guest {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  min-height: 100vh;

  &__content {
    min-height: -webkit-fill-available;
    max-width: 100vw;

    box-sizing: border-box;
    padding: var(--ac-pixel-size-24);

    @include for-tablet-and-up {
      padding: var(--ac-pixel-size-48);
    }
  }
}
