@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-typography {
  @include body-font;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--ac-typography-line-height-160);

  &--alignment-right {
    text-align: right;
  }

  &--whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  &--md {
    font-size: var(--ac-typography-font-size-16);
  }

  &--sm {
    font-size: var(--ac-typography-font-size-14);
  }

  &--xs {
    font-size: var(--ac-typography-font-size-12);
  }

  &--bold {
    font-weight: var(--ac-typography-font-weight-bold);
  }

  &--semibold {
    font-weight: var(--ac-typography-font-weight-semibold);
  }

  &--regular {
    font-weight: var(--ac-typography-font-weight-regular);
  }

  &--light {
    font-weight: var(--ac-typography-font-weight-light);
  }

  &--color-neutral-200 {
    color: var(--ac-color-neutral-200);
  }

  &--color-primary {
    color: var(--ac-color-primary);
  }
}
