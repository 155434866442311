@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-tooltip {
  display: none;
  @include for-desktop-sm-and-up {
    display: initial;
    font-size: var(--ac-pixel-size-12);
    background-color: var(--ac-color-neutral-400);
    padding: var(--ac-pixel-size-8);
    border-radius: var(--ac-pixel-size-7);
    color: var(--ac-color-neutral-white);
  }
}
