@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-project-hours {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &--secondary {
    --ac-color-primary: var(--ac-color-secondary);
  }

  &--tertiary {
    --ac-color-primary: var(--ac-color-tertiary);
  }

  &__project-total-hours {
    @include title-font;
  }

  &__project-hours {
    display: flex;
    gap: 1rem;
  }

  &__hours-overview {
    display: grid;
    gap: 18px;
  }

  &__total {
    @include title-font;
    font-weight: 300;
    color: var(--ac-color-neutral-200);

    span {
      @include body-font;
      font-size: 1.375rem;
      color: #fff;

      strong {
        font-size: 2rem;
        color: var(--ac-color-primary);
      }
    }
  }

  &__progressbar {
    position: relative;
    background: use-alpha(--ac-color-neutral-00, 0.2);
    height: 7px;
    border-radius: 10px;
    overflow: hidden;

    &-open,
    &-planned,
    &-used {
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
      width: 0%;
      transition-delay: 1s;
      transition: width 0.5s ease-in-out;
    }

    &-planned {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #ffffff;
    }

    &-used {
      background: var(--ac-color-primary);
    }
  }

  &__legend {
    display: flex;
    gap: 1.5rem;
    flex-direction: row;

    @media screen and (max-width: 375px) {
      flex-direction: column;
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__dot {
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.5rem;
          border-radius: 50%;

          &_first {
            background-color: var(--ac-color-primary);
          }

          &_second {
            background-color: var(--ac-color-neutral-200);
          }

          &_third {
            background-color: var(--ac-color-neutral-400);
          }
        }

        &__label {
          @include title-font();
          @include font-size(var(--ac-typography-font-size-14));
          line-height: var(--ac-typography-line-height-170);
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
        }

        &__hours {
          @include body-font();
          @include font-size(var(--ac-typography-font-size-14));
          line-height: var(--ac-typography-line-height-125);
          font-weight: 700;
          color: var(--ac-color-neutral-00);
          margin-left: 1rem;
        }
      }
    }
  }
}
