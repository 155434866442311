@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-employee {
  display: grid;
  grid-template-columns: var(--ac-pixel-size-36) 1fr max-content;
  gap: var(--ac-pixel-size-8);
  align-items: center;

  &-image {
    position: relative;
    width: var(--ac-pixel-size-36);
    height: var(--ac-pixel-size-36);
    border-radius: 50%;
    background-size: cover;
    box-sizing: border-box;
    background-clip: padding-box;
    background-color: var(--ac-color-primary);
    border: 4px solid var(--ac-color-transparent);

    &:after {
      content: '';
      transition: border 0.1s ease-in-out;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background-color: transparent;
      border-radius: var(--ac-pixel-size-30);
      border: 1px solid;
      border-color: var(--ac-color-neutral-00);
    }

    &--primary {
      &:after {
        border-color: var(--ac-color-primary);
      }
    }

    &--secondary {
      &:after {
        border-color: var(--ac-color-secondary);
      }
    }
    &--tertiary {
      &:after {
        border-color: var(--ac-color-tertiary);
      }
    }
  }
}
