@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-app-header-account-menu {
  &-element {
    box-sizing: border-box;
    background-color: var(--ac-color-menu-background);
    border-radius: var(--ac-pixel-size-16);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12),
      0px 8px 32px -4px rgba(0, 0, 0, 0.48);
    overflow: hidden;
    min-width: 19.5rem; //According to design specs

    &-top {
      position: relative;
      padding: 1rem;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        height: 1px;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(77, 77, 84, 0) 0%,
          rgba(77, 77, 84, 1) 50%,
          rgba(77, 77, 84, 0) 100%
        );
      }
    }

    &-user {
      display: grid;
      grid-template-columns: 2rem 1fr;
      gap: 1rem;
      align-items: center;

      &-avatar {
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: var(--ac-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 1px solid var(--ac-color-neutral-400);
        outline-offset: 0.125rem;

        &-icon {
          color: var(--ac-color-neutral-500);
          fill: var(--ac-color-neutral-500);
        }
      }

      &-name {
        margin: 0;
      }
    }

    &-bottom {
      &-menu {
        margin: 0;
        box-sizing: border-box;
        padding: 0.75rem;

        &-item {
          display: grid;
          box-sizing: border-box;
          grid-template-columns: 1.5rem 1fr;
          align-items: center;
          gap: 1rem;
          padding: 0.75rem;
          border-radius: var(--ac-pixel-size-16);
          background-color: transparent;
          cursor: pointer;
          color: var(--ac-color-neutral-50);
          fill: var(--ac-color-neutral-50);
          line-height: 2rem;

          &:hover {
            background-color: var(--ac-color-menu-hover);
          }
        }
      }
    }
  }
}
