@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-input {
  position: relative;
  @include body-font();
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding-bottom: var(--ac-pixel-size-28);

  &-label {
    display: block;
    margin-bottom: var(--ac-pixel-size-8);
  }

  &-hint {
    position: absolute;
    bottom:0;
    color: var(--ac-color-neutral-200);
  }

  &-element {
    all: unset;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--ac-color-neutral-200);
    padding: var(--ac-pixel-size-16);
    border-radius: 8px;
    background-color: transparent;
    transition: border-color 0.2s ease;
  }

  &:focus:not(.ac-input--has-error) {
    .ac-input-element {
      border-color: var(--ac-color-neutral-00);
    }
  }

  &--has-error {
    .ac-input-element {
      border-color: var(--ac-color-secondary);
    }

    .ac-input-hint {
      color: var(--ac-color-secondary);
    }
  }
}
