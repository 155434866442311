@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-pill {
  font-size: var(--ac-typography-font-size-14);
  border-radius: var(--ac-pixel-size-16);
  font-weight: var(--ac-typography-font-weight-bold);
  background: rgba(var(--ac-color-secondary-rgb), 0.25);
  padding: var(--ac-pixel-size-4) var(--ac-pixel-size-12);
  display: inline-block;

  &--sm {
    padding: var(--ac-pixel-size-2) var(--ac-pixel-size-6);
    font-size: var(--ac-typography-font-size-12);
  }
}
