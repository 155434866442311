@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-project-employees {
  width: 100%;
  &-list {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    gap: var(--ac-pixel-size-30);
  }
}
