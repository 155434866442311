@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-navigation {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  position: sticky;
  box-sizing: border-box;
  top: calc(100vh - 80px);
  height: 80px;
  background-color: var(--ac-color-neutral-500);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: var(--ac-pixel-size-15);

  &__main {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__logo {
    display: none;
  }
}

@include for-tablet-and-up {
  .ac-navigation {
    left: 0;
    box-sizing: border-box;
    position: sticky;
    top: 1.5rem;
    bottom: 1.5rem;
    height: calc(100% - 3rem);
    width: 100px;
    flex-direction: column;
    justify-content: flex-start;
    background: linear-gradient(
      180deg,
      #23232e 0%,
      rgba(35, 35, 46, 0.65) 100%
    );
    box-shadow: 0px 4px 50px rgba(98, 105, 130, 0.15);
    backdrop-filter: blur(15px);
    border-radius: 0px 15px 15px 0px;
    padding: var(--ac-pixel-size-18);

    &__logo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      box-sizing: border-box;
      margin-bottom: var(--ac-pixel-size-48);
      border-radius: 50%;
      border: 1px solid use-alpha(--ac-color-neutral-00, 0.4);

      &__icon {
        width: var(--ac-pixel-size-28);
        height: auto;
      }
    }

    &__main {
      flex-grow: 1;
      gap: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
}
