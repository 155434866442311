@import '../../styles/globals/mixins';
@import '../../styles/globals/variables';

.ac-layout-authenticated {
  display: grid;
  grid-template-columns: 1fr;

  &__content {
    padding: var(--ac-pixel-size-16);
    min-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;

    &__inner {
      max-width: 100vw;
    }
  }

  @include for-tablet-and-up {
    grid-template-columns: 6.25rem 1fr;
    gap: 1.5rem;

    &__content {
      padding: var(--ac-pixel-size-48);
      min-height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      box-sizing: border-box;
      order: 1;

      &__inner {
        max-width: 1600px;
        margin: 0 auto;
        height: auto;
        min-height: 100%;
      }
    }
  }
}
