@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-checkbox {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    cursor: pointer;

  &__input {
    position: absolute;
    border: 0;
    z-index: -1;
    opacity: 0;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: var(--ac-pixel-size-24);
    flex-shrink: 0;
    margin-right: var(--ac-pixel-size-8);
    width: var(--ac-pixel-size-24);
    height: var(--ac-pixel-size-24);
    border-radius: var(--ac-pixel-size-4);
    background-image: url('../../../assets/icons/checkbox.svg');
  }

  &__input:checked ~ .ac-checkbox__indicator {
    background-image: url('../../../assets/icons/checkbox-checked.svg');
  }

  &__input:focus ~ .ac-checkbox__indicator {
    outline: var(--ac-pixel-size-4) solid var(--ac-color-primary);
    //background: red;
  }
}
