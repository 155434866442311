@import '../../styles/globals/mixins';
@import '../../styles/globals/variables';

.ac-document-detail {

  @include for-mobile {
    &__download {
      order: 1;
    }

    &__address {
      order: 2;
    }
  }
}
.ac-document-detail-signature {
  height: 150px;
}