@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-project {
    &--secondary {
        --ac-color-primary: var(--ac-color-secondary);
    }
    
    &--tertiary {
        --ac-color-primary: var(--ac-color-tertiary);
    }
}