@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-project-planning {
  // grid-column: auto / span 2;
  &-header {
    display: grid;
    grid-template-columns: 1fr max-content;

    &-button {
      path {
        fill: var(--ac-color-primary);
      }

      &--prev {
        margin-right: var(--ac-pixel-size-18);
      }
    }
  }

  &-content {
    overflow-x: auto;
  }

  &-table {
    border-collapse: collapse;
    box-shadow: 0 0 0 1px var(--ac-color-neutral-400) inset;
    border-radius: var(--ac-pixel-size-20);
    width: 100%;

    &-row {
      border-bottom: 1px solid var(--ac-color-neutral-400);

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &-head {
      border-bottom: 1px solid var(--ac-color-neutral-400);
    }

    &-header {
      padding: 0.7rem;
      transition: background-color 0.25s ease;

      &--active {
        background-color: use-alpha(--ac-color-neutral-00, 0.1);
      }

      &:first-of-type {
        border-right: 1px solid var(--ac-color-neutral-400);
      }
    }

    &-data {
      width: 1%;
      text-align: center;
      transition: background-color 0.25s ease;
      padding: var(--ac-pixel-size-16) var(--ac-pixel-size-12);
      position: relative;

      &-hours {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0.5rem;
        bottom: 0.5rem;
        left: 0.5rem;
        right: 0.5rem;
        border-radius: 10px;

        &--primary {
          color: var(--ac-color-neutral-00);
          background-color: var(--ac-color-primary--dark);
        }

        &--secondary {
          color: var(--ac-color-neutral-00);
          background-color: var(--ac-color-secondary--dark);
        }

        &--tertiary {
          color: var(--ac-color-neutral-00);
          background-color: var(--ac-color-tertiary--dark);
        }
      }

      &--active {
        background-color: use-alpha(--ac-color-neutral-00, 0.1);
      }

      &[headers='name'] {
        border-right: 1px solid var(--ac-color-neutral-400);
        text-align: left;
      }
    }

    &--empty {
      padding: 6rem 1rem;
      position: relative;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }
}
