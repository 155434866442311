@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-grid-container {
  display: flex;
  flex-direction: column;

  @include for-tablet-and-up {
    display: grid
  }

  &--full-width {
    width: 100%;
  }

  &--direction-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &-columns {
    &-xs {
      &-1 {
        grid-template-columns: 1fr;
      }

      &-2 {
        grid-template-columns: 1fr 1fr;
      }
    }

    &-sm {
      @include for-tablet-and-up {
        &-1 {
          grid-template-columns: 1fr;
        }

        &-2 {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &-md {
      @include for-desktop-sm-and-up {
        &-1 {
          grid-template-columns: 1fr;
        }

        &-2 {
          grid-template-columns: 1fr 1fr;

          &.ac-grid-container-layout-2-1 {
            grid-template-columns: 2fr 1fr;
          }

          &.ac-grid-container-layout-1-2 {
            grid-template-columns: 1fr 2fr;
          }
        }
        &-3 {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }

    &-lg {
      @include for-desktop-lg-and-up {
        &-1 {
          grid-template-columns: 1fr;
        }

        &-2 {
          grid-template-columns: 1fr 1fr;
          &.ac-grid-container-layout-2-1 {
            grid-template-columns: 2fr 1fr;
          }

          &.ac-grid-container-layout-1-2 {
            grid-template-columns: 1fr 2fr;
          }
        }
      }
    }
  }
}
