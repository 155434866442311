@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-app-header {
  z-index: 99;
  position: sticky;
  padding-top: var(--ac-pixel-size-4);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  top: 0;

  @include for-tablet-and-up {
    position: relative;
    top: initial;
    background: none;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 99;
  }

  &--top {
    display: grid;
    align-items: center;
    padding-bottom: var(--ac-pixel-size-48);
    grid-template-columns: 1fr max-content;
  }

  &__greeting {
    position: relative;

    &__icon {
      position: absolute;
      margin-left: 0.75rem;
      font-size: 0.75em;
      line-height: 1.5em;
    }

    &:hover {
      .ac-app-header__greeting__icon {
        transform-origin: 50% 50%;
        animation: shake 0.4s forwards;
      }
    }
  }

  &--bottom {
    margin-bottom: var(--ac-pixel-size-44);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(25deg);
  }
  70% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
