@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-navigation__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.25rem;
  transition: background-color 0.2s ease, color 0.2s ease;

  &__icon {
    path {
      transition: fill 0.2s ease;
      fill: var(--ac-color-neutral-200);
    }
  }

  &--active {
    color: var(ac-color-black);
    background-color: var(--ac-color-primary);

    .ac-navigation__item__icon {
      path {
        fill: var(--ac-color-neutral-500);
      }
    }
  }

  &:hover:not(.ac-navigation__item--active) {
    background: linear-gradient(
      116.93deg,
      rgba(255, 236, 63, 0.2) 37.23%,
      rgba(255, 230, 0, 0) 100%
    );
    .ac-navigation__item__icon {
      path {
        fill: var(--ac-color-neutral-00);
      }
    }
  }
}
