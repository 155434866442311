@font-face {
  font-family: 'Sora';
  src: local('Sora Regular'), local('Sora-Regular'),
    url('~@assets/fonts/Sora-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: local('Sora Semi Bold'), local('Sora-SemiBold'),
    url('~@assets/fonts/Sora-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: local('Sora Bold'), local('Sora-Bold'),
    url('~@assets/fonts/Sora-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'),
    url('~@assets/fonts/Inter-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
    url('~@assets/fonts/Inter-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('~@assets/fonts/Inter-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
  margin: 0;
}
