@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-contact-card {
  display: flex;
  gap: 2rem;
  justify-content: center;
  text-align: center;

  &__person {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    align-items: center;
    width: 100%;

    @include for-tablet-and-up {
      flex-direction: row;
    }

    &__img {
      display: block;
      background-color: var(--ac-color-primary);
      border-radius: 50%;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      width: 6rem;
      height: 6rem;
    }

    &__info {
      display: flex;
      flex-direction: column;

      &-name {
        margin-bottom: var(--ac-pixel-size-13);
      }

      &-email {
        margin-bottom: var(--ac-pixel-size-16);
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
    }
  }

  &__button-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;

    @include for-tablet-and-up {
      justify-content: flex-start;
    }
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--ac-color-gradient-primary-light);
    opacity: 0.5;
    pointer-events: none;
  }
}
