@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-project-general {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;

  li {
    display: flex;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0.75em;
    font-size: 14px;
    align-items: center;
    gap: 12px;
    font-weight: 300;
    margin: 0;

    strong {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 120px;
    }
  }
}
