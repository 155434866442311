@import '../../styles/globals/mixins';
@import '../../styles/globals/variables';

.ac-login {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &-logo {
    justify-self: flex-start;
    top: 2.5rem;
    left: 0;
    right: 0;
    width: 5rem;
    height: auto;

    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &-panel {
    width: 100%;
    min-width: 300px;

    @include for-tablet-and-up {
     width: 30rem;
    }

    &-container {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    &-error {
      box-sizing: border-box;
      padding: 1rem;
      background-color: var(--ac-color-secondary);
      border-radius: var(--ac-pixel-size-8);
      width: 300px;

      @include for-tablet-and-up {
        width: 30rem;
      }
    }
  }

  &-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
