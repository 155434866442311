@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-note {
  display: flex;
  align-items: center;
  gap: var(--ac-pixel-size-8);

  &--error {
    color: var(--ac-color-error);
  }

  &--hidden {
    display: none;
  }
}
