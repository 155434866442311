@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-collapsable {
  &-content {
    display: inline-block;
    overflow: hidden;
    transition: height 0.2s ease-out;
    margin-bottom: var(--ac-pixel-size-32);
  }

  &--collapsed {
    .ac-collapsable-content {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }
  }
}
