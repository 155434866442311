@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';

.ac-title {
  @include title-font();
  display: block;
  line-height: var(--ac-typography-line-height-120);
  color: var(--ac-color-neutral-00);

  &--1 {
    font-size: var(--ac-pixel-size-36);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-48);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-56);
    }

    // @include for-desktop-lg-and-up {
    //   font-size: var(--ac-pixel-size-64);
    // }
  }

  &--2 {
    font-size: var(--ac-pixel-size-28);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-36);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-44);
    }

    // @include for-desktop-lg-and-up {
    //   font-size: var(--ac-pixel-size-52);
    // }
  }

  &--3 {
    font-size: var(--ac-pixel-size-24);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-28);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-36);
    }

    // @include for-desktop-lg-and-up {
    //   font-size: var(--ac-pixel-size-44);
    // }
  }

  &--4 {
    font-size: var(--ac-pixel-size-20);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-20);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-24);
    }

    // @include for-desktop-lg-and-up {
    //   // font-size: var(--ac-pixel-size-64);
    // }
  }

  &--5 {
    font-size: var(--ac-pixel-size-18);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-18);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-20);
    }

    // @include for-desktop-lg-and-up {
    //   // font-size: var(--ac-pixel-size-64);
    // }
  }

  &--6 {
    font-size: var(--ac-pixel-size-16);

    // @include for-desktop-sm-and-up {
    //   font-size: var(--ac-pixel-size-16);
    // }

    @include for-desktop-md-and-up {
      font-size: var(--ac-pixel-size-18);
    }

    // @include for-desktop-lg-and-up {
    //   font-size: var(--ac-pixel-size-64);
    // }
  }

  &--with-margin {
    margin-bottom: 1.5rem;
  }
}
