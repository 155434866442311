body {
  @include body-font();
  color: var(--ac-body-color);
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    background-color: var(--ac-body-background-color);
    min-height: 100vh;
  }

  a {
    color: var(--ac-color-neutral-00);
  }

  button {
    all: unset;
  }

  &:before {
    content: '';
    position: fixed;
    width: 30vw;
    height: 30vw;
    background-image: url('~@assets/images/blurp-1.svg');
    left: 0;
    top: 0;
    opacity: 0.35;
    filter: blur(12rem);
    z-index: -1;
  }

  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    width: 60vw;
    height: 80vh;
    background-image: url('~@assets/images/blurp-2.svg');
    opacity: 0.35;
    z-index: -1;
    filter: blur(16rem);
  }
}
